<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import TaxonomiesStore from "@/modules/taxonomies/taxonomies-store";
import TaxonomiesPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import TaxonomiesDelete from "@/modules/taxonomies/components/taxonomies-delete.vue";
import TaxonomiesRemove from "@/modules/taxonomies/components/taxonomies-remove.vue";
import TaxonomiesShow from "@/modules/taxonomies/components/taxonomies-show.vue";
import slugify from "slugify";

export default {
  name: "Taxonomies",
  data() {
    return {
      title: "Taxonomies",
      single: "Taxonomies",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Taxonomies", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "URL", key: "slug"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      options: [
        {value: "ON", text: "Ativo"},
        {value: "OFF", text: "Inativo"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id_user: 1,
        name: "",
        slug: "",
        status: "ON",
        description: "",
        image: null,
        video: null,
        audio: null,
        id_taxonomy_parent: null,
        id_taxonomy_type: null,
        order: 1,
        color: "",
        icon: "",
        seo_name: "",
        seo_description: "",
        seo_keys_words: "",
      },
      type: null,
      configEditor: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],

            [{'header': 1}, {'header': 2}],               // custom button values
            [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
            [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
            [{'direction': 'rtl'}],                         // text direction

            [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
            [{'header': [1, 2, 3, 4, 5, 6, false]}],

            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{'align': []}],

            ['clean']                                         // remove formatting button
          ],
        },
        placeholder: 'Digite aqui...',
        theme: 'snow'
      }
    }
  },
  components: {TaxonomiesShow, TaxonomiesRemove, TaxonomiesDelete, TaxonomiesPaginate, Layout, PageHeader},
  watch: {
    "form.name": function (newVal, oldVal) {
      this.form.slug = slugify(newVal);
    },
  },
  mounted() {


    this.type = this.$route.params.taxonomy;
    const id = localStorage.getItem("taxonomy_" + this.type);
    const label = localStorage.getItem("taxonomy_label_" + this.type);
    this.title = label;
    this.single = label;
    this.form.id_taxonomy_type = id;

    this.getPaginate(this.paginate.page);
  },
  methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      TaxonomiesStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        name: null,
        iduuid: null,
      }
    },
    edit(item) {
      this.form = null;
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      const id = localStorage.getItem("taxonomy_" + this.type);
      this.form.id_taxonomy_type = id;
      this.loadingForm = true;
      if (this.form.id) {
        TaxonomiesStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Taxonomies atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        TaxonomiesStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Taxonomies criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div :key="form">
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">


                              <b-tabs>
                                <b-tab title="Principal" class="p-3">
                                  <b-form-group
                                      name="name"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Título"
                                      description="título"
                                      label-for="titulo"
                                  >
                                    <b-form-input for="titulo" v-model="form.name"></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label=""
                                      description=""
                                      label-for="titulo"
                                  >
                                    <p
                                        class="d-flex justify-content-end align-items-center"
                                        @click="editUrl = true"
                                    >
                                      <strong>url:</strong> {{ type }}/<span v-if="!editUrl">{{
                                        form.slug
                                      }}</span>
                                      <b-form-input
                                          v-else
                                          @blur="editUrl = false"
                                          class="w-25"
                                          for="titulo"
                                          v-model="form.slug"
                                      ></b-form-input>
                                    </p>
                                  </b-form-group>

                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Status"
                                      description="Ativo = publicado | Inativo = Agrupado"
                                      label-for="status"
                                  >
                                    <b-form-select
                                        for="status"
                                        v-model="form.status"
                                        :options="options"
                                    ></b-form-select>
                                  </b-form-group>

                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Categoria parent"
                                      description="texto"
                                      label-for="category-parent"
                                  >
                                    <b-form-select
                                        for="category-parent"
                                        v-model="form.id_taxonomy_parent"
                                        :options="taxonomiesOptions"

                                    ></b-form-select>
                                  </b-form-group>

                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Usuário"
                                      description="texto"
                                      label-for="user"
                                  >
                                    <b-form-select
                                        for="user"
                                        v-model="form.id_user"
                                        :options="userOptions"

                                    ></b-form-select>
                                  </b-form-group>

                                  <b-form-group
                                      name="description"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Descrição"
                                      description="descição"
                                      label-for="description"
                                  >

                                    <div class="editor">
                                      <quill output="html" :config="configEditor" v-model="form.description"></quill>
                                    </div>


                                  </b-form-group>
                                  <div>
                                    <b-form-group
                                        id="example text"
                                        label-cols-sm="3"
                                        label-cols-lg="3"
                                        label="Imagem"
                                        label-for="image"
                                    >
                                      <b-form-file
                                          v-model="form.image"
                                          for="image"
                                          placeholder="Escolher imagem"
                                          drop-placeholder="Coloque imagem aqui..."
                                          accept="image/jpeg, image/png, image/gif"
                                          browse-text="Pesquisar"
                                      ></b-form-file>

                                      <img v-if="form.image_view" :src="form.image_view" width="100" alt="">


                                    </b-form-group>

                                    <div class="w-100 d-flex justify-content-end m-2">
                                      <button
                                          v-show="form.image"
                                          type="button"
                                          class="btn btn-danger btn-left-border btn-sm w-25"
                                          @click="cleanFile('image')"
                                      >
                                        Limpar Imagem
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <b-form-group
                                        id="example text"
                                        label-cols-sm="3"
                                        label-cols-lg="3"
                                        label="Vídeo"
                                        label-for="video"
                                    >
                                      <b-form-file
                                          v-model="form.video"
                                          for="video"
                                          placeholder="Escolher vídeo"
                                          drop-placeholder="Coloque vídeo aqui..."
                                          accept="video/mp4"
                                          browse-text="Pesquisar"
                                      ></b-form-file>
                                      <video v-if="form.video_view" width="300" controls>
                                        <source :src="form.video_view" type="video/mp4">
                                      </video>
                                    </b-form-group>
                                    <div class="w-100 d-flex justify-content-end m-2">
                                      <button
                                          v-show="form.video"
                                          type="button"
                                          class="btn btn-danger btn-left-border btn-sm w-25"
                                          @click="cleanFile('video')"
                                      >
                                        Limpar Vídeo
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <b-form-group
                                        id="example text"
                                        label-cols-sm="3"
                                        label-cols-lg="3"
                                        label="Áudio"
                                        label-for="audio"
                                    >
                                      <b-form-file
                                          v-model="form.audio"
                                          for="audio"
                                          placeholder="Escolher áudio"
                                          drop-placeholder="Coloque áudio aqui..."
                                          browse-text="Pesquisar"
                                      ></b-form-file>

                                      <audio v-if="form.audio_view" controls>
                                        <source :src="form.audio_view" type="audio/mp3">
                                      </audio>

                                    </b-form-group>
                                    <div class="w-100 d-flex justify-content-end m-2">
                                      <button
                                          v-show="form.audio"
                                          type="button"
                                          class="btn btn-danger btn-left-border btn-sm w-25"
                                          @click="cleanFile('audio')"
                                      >
                                        Limpar Áudio
                                      </button>
                                    </div>
                                  </div>
                                </b-tab>
                                <b-tab title="Configurações" class="p-3">
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Ordem"
                                      description="texto"
                                      label-for="order"
                                  >
                                    <b-form-input for="order" v-model="form.order"></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Cor"
                                      description="texto"
                                      label-for="color"
                                  >
                                    <b-form-input
                                        for="color"
                                        type="color"
                                        size="sm"
                                        v-model="form.color"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Ícone"
                                      description="texto"
                                      label-for="icone"
                                  >
                                    <b-form-input for="icone" v-model="form.icon"></b-form-input>
                                  </b-form-group>
                                </b-tab>
                                <b-tab title="SEO" class="p-3">
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Título SEO"
                                      description="texto"
                                      label-for="title-seo"
                                  >
                                    <b-form-input for="title-seo" v-model="form.seo_name"></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Url/slug"
                                      description="texto"
                                      label-for="slug"
                                  >
                                    <b-form-input for="slug" v-model="form.slug"></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Metadescrição"
                                      description="texto"
                                      label-for="metadescription"
                                  >

                                    <div class="editor">
                                      <quill output="html" :config="configEditor" v-model="form.seo_description"></quill>
                                    </div>
                                  </b-form-group>
                                  <b-form-group
                                      id="example text"
                                      label-cols-sm="3"
                                      label-cols-lg="3"
                                      label="Chaves de busca"
                                      description="texto"
                                      label-for="seo_keys_words"
                                  >
                                    <div class="editor">
                                      <quill output="html" :config="configEditor" v-model="form.seo_keys_words"></quill>
                                    </div>
                                  </b-form-group>
                                </b-tab>
                              </b-tabs>


                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <taxonomies-paginate :paginate="paginate" @change-page="getPaginate"></taxonomies-paginate>
               <b-table striped hover :items="list" :fields="table" class="  table-responsive-sm table-responsive-md">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <taxonomies-delete :value="data.item" @change="getPaginate(1)"></taxonomies-delete>
                  <taxonomies-remove :value="data.item" @change="getPaginate(1)"></taxonomies-remove>
                  <taxonomies-show :value="data.item"></taxonomies-show>
                </template>
              </b-table>
              <taxonomies-paginate :paginate="paginate" @change-page="getPaginate"></taxonomies-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style  >
.editor {
  height: 350px;
}
.editor .segment {
  height: 300px;
}
</style>

